import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  Button,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { CardDetails } from './types';
import {
  createPaymentIntent,
  fetchSavedPaymentMethods,
  verifyPayment,
} from '../../../api/paymentsApi';
import Add from '@mui/icons-material/Add';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import LoadingSpinner from '../loading';
import { usePaymentFormStyles } from './styles';
import { toast } from 'react-toastify';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: '#666EE8',
    },
    invalid: {
      color: '#e53e3e',
      iconColor: '#e53e3e',
    },
  },
  showIcon: true,
};

interface PaymentFormProps {
  amount: number;
  onPaymentSuccess: () => void;
}

const PaymentForm = ({ amount, onPaymentSuccess }: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const classes = usePaymentFormStyles();
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<CardDetails[]>(
    []
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAddCard, setShowAddCard] = useState<boolean>(false);

  useEffect(() => {
    const fetchMethods = async () => {
      const methods = await fetchSavedPaymentMethods();
      setSavedPaymentMethods(methods);
      const defaultMethod = methods.find((method) => method.isDefault);
      if (defaultMethod) {
        setSelectedPaymentMethod(defaultMethod.id);
      } else if (methods.length > 0) {
        setSelectedPaymentMethod(methods[0].id);
      }
      setIsLoading(false);
    };

    fetchMethods();
  }, []);

  const handlePayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements || amount <= 9) {
      console.error(
        'Stripe not loaded, elements not found, or invalid amount.'
      );
      return;
    }

    setIsLoading(true);

    const amountInCents = amount * 100;

    try {
      const paymentMethodId = selectedPaymentMethod
        ? selectedPaymentMethod
        : undefined;
      const {
        data: { clientSecret },
      } = await createPaymentIntent(amountInCents, saveCard, paymentMethodId);

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId || {
          card: elements.getElement(CardNumberElement)!,
          billing_details: {},
        },
      });

      if (paymentResult.error) {
        console.error(paymentResult.error.message);
      } else if (
        paymentResult.paymentIntent &&
        paymentResult.paymentIntent.status === 'succeeded'
      ) {
        await verifyPayment(paymentResult.paymentIntent.id);
        queryClient.invalidateQueries('userInfo');
        queryClient.invalidateQueries('transactions');
        toast.success(`Payment success, ${amount} tokens added`);
        onPaymentSuccess();
      }
    } catch (error) {
      console.error('Payment failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAddCard = () => {
    setShowAddCard(!showAddCard);
  };

  return (
    <Box>
      <Typography variant="h6" sx={classes.typography}>
        Payment method
      </Typography>
      <form onSubmit={handlePayment} style={{ opacity: isLoading ? 0.5 : 1 }}>
        {savedPaymentMethods.length > 0 && !showAddCard ? (
          <>
            <FormControl component="fieldset" sx={classes.formControl}>
              <RadioGroup
                aria-label="saved-cards"
                name="saved-cards"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                sx={classes.radioGroup}
              >
                {savedPaymentMethods.map((method) => (
                  <FormControlLabel
                    key={method.id}
                    value={method.id}
                    control={<Radio />}
                    label={`${method.brand.charAt(0).toUpperCase() + method.brand.slice(1)} **** ${method.last4}`}
                    sx={classes.formControlLabel}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Button
              type="button"
              onClick={toggleAddCard}
              startIcon={<Add />}
              sx={classes.addCardButton}
            >
              Add card
            </Button>
          </>
        ) : (
          <>
            <TextField
              label="Card's owner"
              variant="outlined"
              placeholder="Name as it appears on card"
              fullWidth
              margin="normal"
              sx={classes.textField}
            />
            <Box sx={classes.cardInput}>
              <CardNumberElement options={ELEMENT_OPTIONS} />
            </Box>
            <Box sx={classes.cardDetailsContainer}>
              <Box sx={{ ...classes.cardInput, flex: 1 }}>
                <CardExpiryElement options={ELEMENT_OPTIONS} />
              </Box>
              <Box sx={{ ...classes.cardInput, flex: 1 }}>
                <CardCvcElement options={ELEMENT_OPTIONS} />
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveCard}
                  onChange={(e) => setSaveCard(e.target.checked)}
                />
              }
              label="Save card for future use"
              sx={classes.checkboxFormControlLabel}
            />
            {savedPaymentMethods.length > 0 && (
              <Button
                onClick={toggleAddCard}
                sx={classes.useExistingCardButton}
                startIcon={<ChevronLeft />}
              >
                Use existing card
              </Button>
            )}
          </>
        )}
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || amount < 10}
          sx={classes.button}
        >
          {isLoading ? 'Processing...' : `Purchase (${amount.toFixed(2)} EUR)`}{' '}
        </Button>
      </form>
      {isLoading && (
        <Box sx={classes.loadingOverlay}>
          <LoadingSpinner small />
        </Box>
      )}
    </Box>
  );
};

export default PaymentForm;
